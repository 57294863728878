import React, { useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useSprings, animated } from 'react-spring'

import Layout from '../components/layout'
import SEO from '../components/seo'
import produtosStyles from './produtos.module.scss'

const ProdutosPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark (
        sort: { fields: frontmatter___order, order: ASC}
        filter: { fileAbsolutePath: { regex: "\/produtos/" } }
      ){
        edges {
          node {
            frontmatter {
              title,
              quickDescription
              cardImage {
                childImageSharp {
                  fluid (quality: 10) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
          }
        }
      },
    }
  `)


  const quantidadeProdutos = data.allMarkdownRemark.edges.length
  const changeParams = (indexSelected, newScale) => index => {
    return indexSelected === index
      ? {
        scale: newScale,
        config: { mass: 3, tension: 550, friction: 20 }
      }
      : {
        scale: 1,
        config: { mass: 3, tension: 550, friction: 20 }
      }
  }
  
  const order = useRef(data.allMarkdownRemark.edges.map((_, index) => index)) // Cria um array com os indices
  const [springs, setSpring] = useSprings(
    quantidadeProdutos,
    changeParams(order.current)
  )

  return (
    <Layout>
      <SEO title="Produtos" />
      <div className={produtosStyles.mainContent}>
        <div className={produtosStyles.produtosTexto}>
          <h1>Produtos</h1>
          <p>Conheça os nossos produtos</p>
        </div>
      </div>
      <ol className={produtosStyles.products}>
        {data.allMarkdownRemark.edges.map((edge, index) => {
          const { cardImage, title, quickDescription } = edge.node.frontmatter
          const { slug } = edge.node.fields
          
          return (
            <li className={produtosStyles.product} key={slug}>

              <animated.div
                className={produtosStyles.card}
                onMouseOver={() => setSpring(changeParams(index, 1.02))}
                onMouseLeave={() => setSpring(changeParams(index, 1))}
                style={{ transform: springs[index].scale.interpolate(s => `scale(${s})`)}}
              >
                <Link to={`/produtos/${slug}`}>
                </Link>
                <Img fluid={cardImage.childImageSharp.fluid} alt={title}></Img>
                <div className={produtosStyles.productContent}>
                  <h4>{title}</h4>
                  <p>{quickDescription}</p>
                </div>
              </animated.div>
            </li>
          )
        })}
      </ol>
    </Layout>
  );
}

export default ProdutosPage